import { Link } from "gatsby";
import React from "react";

const UtilityNav = ({ headerStyle }) => {
  return (
    <div
      id="utlity-navigation"
      className={`py-1.5 hidden lg:block ${
        headerStyle === "overlap" ? "bg-black" : "bg-gradient-to-r from-[#F19A49]/80 to-[#D9892A]"
      }`}
    >
      <div className="container">
        <div className="w-full flex justify-end items-center text-sm text-white lg:grid grid-cols-[1fr_3fr_1fr]">
          <div className="col-start-2">
            Congratulations to Katherine C. Burke, JBP Legal’s Newest Shareholder!{" "}
            <Link
              to="/our-team/katherine-c-burke/"
              className={`${
                headerStyle === "overlap" ? "text-primary-600" : "text-white"
              }  font-bold`}
            >
              Meet Katherine.
            </Link>
          </div>
          <div className="flex items-center col-start-3">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1.5"
            >
              <path
                d="M1.08124.769072L4.33117.019088c.35312-.081249.71561.103122.85936.434365L6.6905 3.95338c.13125.30624.04375.66561-.21562.87498L4.58117 6.37833c1.12498 2.39682 3.09056 4.39057 5.53743 5.53737l1.5499-1.8937c.2125-.25937.5688-.34687.875-.21562l3.4999 1.49992c.3344.1469.5188.5094.4375.8625l-.75 3.25c-.0781.3375-.3781.5812-.7312.5812C6.99674 16 .5 9.51576.5 1.50031c0-.35.240621-.653115.58124-.731238z"
                className={`fill-current text-primary-600 ${
                  headerStyle === "overlap" ? "text-primary-600" : "text-white"
                }`}
              />
            </svg>
            <a
              href="tel:720-491-3117"
              className={`text-sm font-semibold ${
                headerStyle === "overlap"
                  ? "text-primary-600 hover:text-primary-800"
                  : "text-white hover:text-white"
              }`}
            >
              (720) 491-3117
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UtilityNav;
